import {
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import "../Main/Table.css";
import TableCombo from "../Util/TableCombo";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getCode, getDPTCDDropDown, getUserListDropDown } from "../API/MainAPI";
import { getUserListAll } from "../API/UserAPI";
import { AppContext } from "../AppContext";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminDeptEdit({
  flag,
  data,
  setData,
  saveData,
  cancelData,
}) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [deptClicked, setDeptClicked] = useState({});
  const [deptDropDown, setDeptDropDown] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userDropDown, setUserDropDown] = useState([]);
  const [userClicked, setUserClicked] = useState({});
  const [trmDropDown, setTrmDropDown] = useState([]);
  const [trmClicked, setTrmClicked] = useState({});
  const comboTableRef = useRef();

  useEffect(() => {
    getUserListAll(setErrFlag, setLoadFlag)
      .then((result) => result && setUserList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    getDPTCDDropDown()
      .then((result) => result && setDeptDropDown(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    getUserListDropDown()
      .then((result) => result && setUserDropDown(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    getCode("TRMCD")
      .then((result) => result && setTrmDropDown(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setDeptClicked({});
        setUserClicked({});
        setTrmClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeData = (value, col, deptSid) => {
    const update = data.map((item) =>
      item.deptSid === deptSid
        ? { ...item, [col]: value, changeFlag: "Y" }
        : item
    );
    setData(update);

    if (col === "parentDeptGuid") {
      setDeptClicked({});
    } else if (col === "userGuid") {
      setUserClicked({});
    } else if (col === "terminal") {
      setTrmClicked({});
    }
  };

  const deptTableOpen = (index) => {
    setDeptClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const userTableOpen = (index) => {
    setUserClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const trmTableOpen = (index) => {
    setTrmClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Paper
          sx={{
            p: 2,
            mb: 1,
            ml: 5,
          }}
        >
          <Box
            sx={{
              width: 800,
              pt: 1,
              pl: 2,
              pr: 2,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 3,
                mb: 3,
                ml: 2,
              }}
            >
              <Typography className="menuTitle">부서 관리</Typography>
              <Box sx={{ mr: 1 }}>
                <SaveButton
                  variant="contained"
                  sx={{ mr: 1 }}
                  onClick={saveData}
                >
                  저장
                </SaveButton>
                <CancelButton variant="outlined" onClick={cancelData}>
                  취소
                </CancelButton>
              </Box>
            </Box>
            <TableContainer
              sx={{
                borderRadius: 1,
              }}
            >
              <Table className="dept-table">
                {" "}
                <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                  <TableRow>
                    <TableCell>부서명</TableCell>
                    <TableCell>순서</TableCell>
                    <TableCell>상위부서</TableCell>
                    <TableCell>부서대표유저</TableCell>
                    <TableCell>터미널</TableCell>
                  </TableRow>
                </TableHead>{" "}
                <TableBody>
                  {data &&
                    data
                      .filter((item) => item.deptSid === flag.deptSid)
                      .map((row, index) => (
                        <TableRow>
                          <TableCell>
                            <Input
                              className="admin-input"
                              sx={{
                                "&::before": {
                                  borderBottom: "none",
                                },
                                "&::after": {
                                  borderBottom: "none",
                                },
                              }}
                              value={row.deptName}
                              onChange={(e) => {
                                e.stopPropagation();
                                changeData(
                                  e.target.value,
                                  "deptName",
                                  row.deptSid
                                );
                              }}
                            ></Input>
                          </TableCell>
                          <TableCell>
                            <Input
                              className="admin-input"
                              sx={{
                                "&::before": {
                                  borderBottom: "none",
                                },
                                "&::after": {
                                  borderBottom: "none",
                                },
                              }}
                              value={row.deptSeq}
                              onChange={(e) => {
                                e.stopPropagation();
                                changeData(
                                  e.target.value,
                                  "deptSeq",
                                  row.deptSid
                                );
                              }}
                            ></Input>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                justifyContent: "center",
                              }}
                            >
                              <OutlinedInput
                                size="small"
                                className="admin-input"
                                variant="outlined"
                                value={
                                  row.parentDeptSid &&
                                  data.find(
                                    (item) => item.deptSid === row.parentDeptSid
                                  )?.deptName
                                }
                                onClick={(event) => {
                                  deptTableOpen(index);
                                  event.stopPropagation();
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(event) => {
                                        deptTableOpen(index);
                                        event.stopPropagation();
                                      }}
                                    >
                                      {deptClicked[index] ? (
                                        <ArrowDropUpIcon />
                                      ) : (
                                        <ArrowDropDownIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <Box
                                ref={deptClicked[index] ? comboTableRef : null}
                              >
                                {deptClicked[index] && (
                                  <TableCombo
                                    tableList={deptDropDown && deptDropDown}
                                    changeData={(value) =>
                                      changeData(
                                        value,
                                        "parentDeptSid",
                                        row.deptSid
                                      )
                                    }
                                    type={"DEPT"}
                                  />
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                justifyContent: "center",
                                paddingLeft: 1,
                              }}
                            >
                              <OutlinedInput
                                size="small"
                                className="admin-input"
                                variant="outlined"
                                value={
                                  row.userSid && userList
                                    ? userList.find(
                                        (item) => item.userSid === row.userSid
                                      )?.userName
                                    : ""
                                }
                                onClick={(event) => {
                                  userTableOpen(index);
                                  event.stopPropagation();
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(event) => {
                                        userTableOpen(index);
                                        event.stopPropagation();
                                      }}
                                    >
                                      {userClicked[index] ? (
                                        <ArrowDropUpIcon />
                                      ) : (
                                        <ArrowDropDownIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <Box
                                ref={userClicked[index] ? comboTableRef : null}
                              >
                                {userClicked[index] && (
                                  <TableCombo
                                    tableList={userDropDown && userDropDown}
                                    changeData={(value) =>
                                      changeData(value, "userSid", row.deptSid)
                                    }
                                    type={"USER"}
                                    width={"15%"}
                                  />
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                justifyContent: "center",
                                paddingLeft: 1,
                              }}
                            >
                              <OutlinedInput
                                size="small"
                                className="admin-input"
                                variant="outlined"
                                value={
                                  row.terminal && trmDropDown
                                    ? trmDropDown.find(
                                        (item) => item.value2 === row.terminal
                                      )?.value2 || ""
                                    : ""
                                }
                                onClick={(event) => {
                                  trmTableOpen(index);
                                  event.stopPropagation();
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(event) => {
                                        trmTableOpen(index);
                                        event.stopPropagation();
                                      }}
                                    >
                                      {trmClicked[index] ? (
                                        <ArrowDropUpIcon />
                                      ) : (
                                        <ArrowDropDownIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <Box
                                ref={trmClicked[index] ? comboTableRef : null}
                              >
                                {trmClicked[index] && (
                                  <TableCombo
                                    tableList={trmDropDown && trmDropDown}
                                    changeData={(value) =>
                                      changeData(value, "terminal", row.deptSid)
                                    }
                                    type={"TRMCD"}
                                    width={"12%"}
                                  />
                                )}
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
