import "./App.css";
import Login from "./Login/Login";
import Fail from "./Util/Fail";
import { useEffect, useState, createContext, useContext } from "react";
import {
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./Main/Main.css";
import Main from "../src/Main/Main";
import Loading from "./Util/Loading";
import Signup from "./Login/Signup";
import { AppProvider, AppContext } from "./AppContext";
import AdminMenu from "./Admin/AdminMenu";
import AdminUser from "./Admin/AdminUser";
import AdminDept from "./Admin/AdminDept";
import AdminCommon from "./Admin/AdminCommon";
import AdminAirlines from "./Admin/Airlines/AdminAirlines";
import DgList from "./Admin/DgList";

function App() {
  const apiUrl = process.env.REACT_APP_API_SOCKETURL;
  const navigator = useNavigate();
  const location = useLocation();
  const { setErrFlag, errFlag, setSocket, loadFlag, socket, setAlarmData } =
    useContext(AppContext);
  useEffect(() => {
    if (location.pathname === "/Signup") {
      return;
    }

    const jwt = sessionStorage.getItem("jwt");

    if (socket) {
      let data = "";
      socket.onopen = () => {
        navigator(sessionStorage.getItem("path"));
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message && message.loginFlag === "N") {
          data = message.message;

          socket.close();
          sessionStorage.clear();
        } else if (message && message.alarmArray) {
          setAlarmData(Object(message.alarmArray));
        }
      };

      socket.onclose = () => {
        sessionStorage.clear();
        navigator("/");
      };

      socket.onerror = (error) => {
        sessionStorage.clear();
        navigator("/");
        setErrFlag({ code: "Y", data: error });
      };
    } else {
      if (jwt) {
        setSocket(new WebSocket(`${apiUrl}/conSocket?token=${jwt}`));
        return;
      } else {
        navigator("/");
      }
    }

    return () => {
      if (socket) {
        socket.close();
        sessionStorage.clear();
      }
    };
  }, [socket]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route path="/Main/:value1?/:value2?" element={<Main />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/AdminMenu" element={<AdminMenu />} />
        <Route path="/AdminUser" element={<AdminUser />} />
        <Route path="/AdminDept" element={<AdminDept />} />
        <Route path="/AdminCommon" element={<AdminCommon />} />
        <Route path="/DgList/:value1?/:value2?" element={<DgList />} />
        <Route path="/AdminAirlines/:value1?" element={<AdminAirlines />} />
      </Routes>

      <Fail props={errFlag} />
      <Loading load={loadFlag} />
    </div>
  );
}

export default function AppWithProviders() {
  return (
    <AppProvider>
      <App />
    </AppProvider>
  );
}
