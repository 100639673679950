import { Alert, Button, IconButton } from "@mui/joy";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";

export default function Fail({ props }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    if (props.code === "Y") {
      setOpen(true);
      setData(props.data);
    } else {
      setOpen(false);
      setData(props.data);
    }
  }, [props]);

  const closeBtn = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: "auto",
        right: 0,
        padding: "16px",
        width: "30%",
        display: open === true ? "block" : "none",
        zIndex: 9999,
      }}
    >
      <Alert
        startDecorator={<WarningIcon />}
        variant="solid"
        color="danger"
        endDecorator={
          <React.Fragment>
            <IconButton
              onClick={(e) => (e.stopPropagation(), closeBtn())}
              variant="solid"
              size="sm"
              color="danger"
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        {data}
      </Alert>
    </div>
  );
}
