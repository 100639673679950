import React, { useEffect } from "react";
import { Box, InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

function SearchAlert({ props }) {
  const [value, setValue] = useState();
  useEffect(() => {
    props(value);
  }, [value]);
  const searchClick = () => {
    props(value);
  };
  const valueChange = (event) => {
    setValue(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      searchClick();
    }
  };
  return (
    <Box
      component="form"
      sx={{
        p: "0px 4px",
        display: "flex",
        alignItems: "center",
        width: "50%",
        borderBottom: "1px solid gray",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: 15 }}
        placeholder="검색"
        value={value}
        onChange={valueChange}
        onKeyPress={handleKeyPress}
      />
      <IconButton
        type="button"
        sx={{ p: "5px" }}
        aria-label="search"
        onClick={searchClick}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  );
}

export default SearchAlert;
