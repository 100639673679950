import React from "react";
import { Button, Snackbar, Alert } from "@mui/material";

export default function YNAlert({ contents, handleClickYes, handleClickNo }) {
  const show = true;

  const action = (
    <React.Fragment>
      <Button sx={{ color: "#DD7017" }} size="small" onClick={handleClickYes}>
        예
      </Button>
      <Button sx={{ color: "#DD7017" }} size="small" onClick={handleClickNo}>
        아니오
      </Button>
    </React.Fragment>
  );
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={show}
        onClose={handleClickNo}
      >
        <Alert
          onClose={handleClickNo}
          severity="info"
          color="warning"
          action={action}
          sx={{ width: "100%", fontFamily: "Noto Sans KR" }}
        >
          {contents}
        </Alert>
      </Snackbar>
    </>
  );
}
