import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

const Loading = ({ load }) => {
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    setFlag(load);
  }, [load]);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={flag}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default Loading;
