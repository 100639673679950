import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  TextField,
  TableContainer,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Paper,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
export default function FilterList({
  filterList,
  viewStr,
  onFilterChange,
  resetFlag,
  setResetFlag,
  width,
}) {
  const [checked, setChecked] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [open, setOpen] = useState(false);
  const filterRef = useRef();

  useEffect(() => {
    if (resetFlag) {
      setChecked([]);
      setResetFlag(false);
    }
  }, [resetFlag, setResetFlag]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (filterList && searchTerm) {
      const filteredList = filterList.filter((item) =>
        item[viewStr].toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredList(filteredList);
    } else {
      setFilteredList(filterList);
    }
  }, [searchTerm, filterList, viewStr]);

  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value.guid);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.guid);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onFilterChange(newChecked);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Box ref={filterRef}>
      <IconButton onClick={handleClick}>
        <FilterAltIcon />
      </IconButton>
      {open && (
        <Paper sx={{ p: 1, position: "absolute", zIndex: 999 }}>
          <TextField
            size="small"
            variant="standard"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search"
            sx={{ mt: 1, mb: 1, width: width }}
            autoComplete="off"
          />
          <TableContainer sx={{ maxHeight: "30vh", width: width }}>
            <Table>
              <TableBody>
                {filteredList &&
                  filteredList.map((value) => (
                    <TableRow
                      key={value.guid}
                      hover
                      role="checkbox"
                      onClick={handleToggle(value)}
                      selected={checked.includes(value.guid)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={checked.includes(value.guid)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell sx={{ fontFamily: "Nato Sans KR" }}>
                        {value[viewStr]}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
}
