import React from "react";
import AlertMain from "../Alert/AlertMain";
import { getUserListAll } from "../API/UserAPI";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import { getMenuDropDown } from "../API/MenuAPI";
import { setUserSave, setUserDelete, adminLogout } from "../API/UserAPI";
import {
  getDeptMostListDropDown,
  getDeptLowUserListExcept,
} from "../API/DeptAPI";
import AdminUserModal from "./AdminUserModal";
import "../Main/Table.css";
import TableCombo from "../Util/TableCombo";
import { getDPTCDDropDown } from "../API/MainAPI";
import Online from "../image/online.png";
import Offline from "../image/offline.png";
import YNAlert from "../Util/YNAlert";
import { getAirCombo } from "../API/AirAPI";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminUser() {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [deptCombo, setDeptCombo] = useState([]);
  const [airCombo, setAirCombo] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [mainDept, setMainDept] = useState([]);
  const [deptAll, setDeptAll] = useState([]);
  const [dptCode, setDptCode] = useState([]);
  const [flag, setFlag] = useState(false);
  const [open, setOpen] = useState(0);
  const [deptClicked, setDeptClicked] = useState({});
  const [deptMainClicked, setDeptMainClicked] = useState({});
  const [pathClicked, setPathClicked] = useState({});
  const [ynAlertShow, setYNAlertShow] = useState(null);
  const comboTableRef = useRef();
  const [aprFlag, setAprFlag] = useState([
    { name: "대기", value: "W" },
    { name: "승인", value: "Y" },
    { name: "거절", value: "N" },
  ]);

  useEffect(() => {
    getUserListAll(setErrFlag, setLoadFlag)
      .then((result) => result && (setData(result), setOriginData(result)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, [flag]);

  useEffect(() => {
    getDPTCDDropDown()
      .then((result) => result && setDeptAll(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    getDeptMostListDropDown()
      .then((result) => result && setMainDept(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    getMenuDropDown(setErrFlag, setLoadFlag)
      .then((result) => result && setMenuList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    const convDeptList = () => {
      const tempData = [];

      mainDept.forEach((item) =>
        getDeptLowUserListExcept(item.guid)
          .then(
            (result) =>
              result && tempData.push({ guid: item.guid, data: result })
          )
          .catch((error) => setErrFlag({ code: "Y", data: error.message }))
      );
      setDptCode(tempData);
    };

    convDeptList();
  }, [mainDept]);

  useEffect(() => {
    getAirCombo(setErrFlag, setLoadFlag)
      .then((result) => result && setAirCombo(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    const convAir = [
      {
        value1: mainDept.length,
        guid: 0,
        value2: "항공사",
      },
    ];

    if (!mainDept.some((dept) => dept.guid === 0)) {
      setDeptCombo([...mainDept, ...convAir]);
    }
  }, [mainDept]);

  const changeData = (value, col, colIndex) => {
    setData((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );

    if (col === "userDefaultPathSid") {
      setPathClicked({});
    } else if (col === "userSubDeptSid") {
      setDeptClicked({});
    } else if (col === "userMainDeptSid") {
      setDeptMainClicked({});
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setDeptClicked({});
        setPathClicked({});
        setDeptMainClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userSave = () => {
    const saveData = data.filter((item) => item.changeFlag === "Y");

    setUserSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const userCancel = () => {
    setData(originData);
  };

  const userDelete = (userSid) => {
    setUserDelete(userSid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const colloOpen = (userSid) => {
    if (open === userSid) {
      setOpen(0);
    } else {
      setOpen(userSid);
    }
  };

  const deptTableOpen = (index) => {
    setDeptClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const deptMainTableOpen = (index) => {
    setDeptMainClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const pathTableOpen = (index) => {
    setPathClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleOnlineFlag = (userGuid) => {
    setYNAlertShow(userGuid);
  };

  const handleClickYes = () => {
    if (ynAlertShow) {
      adminLogout(ynAlertShow, setErrFlag, setLoadFlag)
        .then((result) => result && (setFlag(!flag), handleClickNo()))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  };

  const handleClickNo = () => {
    setYNAlertShow(null);
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              mb: 2,
              ml: 2,
            }}
          >
            <Typography className="menuTitle">사용자 관리</Typography>
            <Box sx={{ mr: 1 }}>
              <SaveButton variant="contained" sx={{ mr: 1 }} onClick={userSave}>
                저장
              </SaveButton>
              <CancelButton variant="outlined" onClick={userCancel}>
                취소
              </CancelButton>
            </Box>
          </Box>
          <TableContainer sx={{ borderRadius: 1, width: "90vw" }}>
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                <TableRow>
                  <TableCell sx={{ width: "3%" }}></TableCell>
                  <TableCell sx={{ width: "5%" }}></TableCell>
                  <TableCell sx={{ width: "14%" }}>ID</TableCell>
                  <TableCell sx={{ width: "15%" }}>패스워드</TableCell>
                  <TableCell sx={{ width: "10%" }}>이름</TableCell>
                  <TableCell sx={{ width: "15%" }}>법인명</TableCell>
                  <TableCell sx={{ width: "15%" }}>부서 및 항공사명</TableCell>
                  <TableCell sx={{ width: "8%" }}>승인 상태</TableCell>
                  <TableCell sx={{ width: "10%" }}>최초 경로</TableCell>
                  <TableCell sx={{ width: "3%" }}>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <React.Fragment>
                      <TableRow key={index}>
                        <TableCell sx={{ padding: 0 }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(event) => (
                              colloOpen(row.userSid), event.stopPropagation()
                            )}
                          >
                            {open === row.userSid ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {row.onlineFlag === "Y" ? (
                            <img
                              src={Online}
                              style={{ width: "0.7vw", cursor: "pointer" }}
                              onClick={(event) => (
                                event.stopPropagation(),
                                handleOnlineFlag(row.userSid)
                              )}
                              alt="online"
                            />
                          ) : (
                            <img
                              src={Offline}
                              style={{ width: "0.5vw" }}
                              alt="offline"
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.userId}
                            onChange={(e) => {
                              changeData(e.target.value, "userId", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.userPassword}
                            onChange={(e) => {
                              changeData(e.target.value, "userPassword", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.userName}
                            onChange={(e) => {
                              changeData(e.target.value, "userName", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              justifyContent: "center",
                            }}
                          >
                            <Input
                              className="admin-input"
                              value={
                                row.userMainDeptSid &&
                                deptCombo &&
                                deptCombo.find(
                                  (item) => item.guid === row.userMainDeptSid
                                )?.guid === 0
                                  ? "항공사"
                                  : deptCombo.find(
                                      (item) =>
                                        item.guid === row.userMainDeptSid
                                    )?.value2
                              }
                              onClick={(event) => {
                                deptMainTableOpen(index);
                                event.stopPropagation();
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={(event) => {
                                      deptMainTableOpen(index);
                                      event.stopPropagation();
                                    }}
                                  >
                                    {deptMainClicked[index] ? (
                                      <ArrowDropUpIcon />
                                    ) : (
                                      <ArrowDropDownIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <Box
                              ref={
                                deptMainClicked[index] ? comboTableRef : null
                              }
                            >
                              {deptMainClicked[index] && (
                                <TableCombo
                                  tableList={deptCombo}
                                  changeData={(value) =>
                                    changeData(value, "userMainDeptSid", index)
                                  }
                                  type={"DEPT"}
                                />
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              justifyContent: "center",
                            }}
                          >
                            <Input
                              className="admin-input"
                              value={
                                row.userSubDeptSid &&
                                row.userSubDeptSid !== null &&
                                row.userSubDeptSid !== undefined &&
                                row.userMainDeptSid === 0
                                  ? airCombo &&
                                    airCombo.find(
                                      (item) => item.guid === row.userSubDeptSid
                                    ) &&
                                    airCombo.find(
                                      (item) => item.guid === row.userSubDeptSid
                                    )?.value3
                                  : deptAll &&
                                    deptAll.find(
                                      (item) => item.guid === row.userSubDeptSid
                                    ) &&
                                    deptAll.find(
                                      (item) => item.guid === row.userSubDeptSid
                                    )?.value2
                              }
                              onClick={(event) => {
                                deptTableOpen(index);
                                event.stopPropagation();
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={(event) => {
                                      deptTableOpen(index);
                                      event.stopPropagation();
                                    }}
                                  >
                                    {deptClicked[index] ? (
                                      <ArrowDropUpIcon />
                                    ) : (
                                      <ArrowDropDownIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <Box
                              ref={deptClicked[index] ? comboTableRef : null}
                            >
                              {deptClicked[index] && (
                                <TableCombo
                                  tableList={
                                    row.userMainDeptSid === 0
                                      ? airCombo
                                      : dptCode &&
                                        dptCode
                                          .filter(
                                            (item) =>
                                              item.guid === row.userMainDeptSid
                                          )
                                          .map((item) => item.data)[0]
                                  }
                                  changeData={(value) =>
                                    changeData(value, "userSubDeptSid", index)
                                  }
                                  type={
                                    row.userMainDeptSid === 0 ? "CRRCD" : "DEPT"
                                  }
                                />
                              )}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Select
                            variant="standard"
                            fullWidth
                            value={row.userApprove}
                            onChange={(e) =>
                              changeData(e.target.value, "userApprove", index)
                            }
                          >
                            {aprFlag &&
                              aprFlag.map((rowMenu) => (
                                <MenuItem
                                  key={rowMenu.id}
                                  value={rowMenu.value}
                                >
                                  {rowMenu.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Input
                            className="admin-input"
                            value={
                              row.userDefaultPathSid &&
                              menuList
                                .filter(
                                  (item) => item.guid === row.userDefaultPathSid
                                )
                                .map((item) => item.value2)
                            }
                            onClick={(event) => {
                              pathTableOpen(index);
                              event.stopPropagation();
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(event) => {
                                    pathTableOpen(index);
                                    event.stopPropagation();
                                  }}
                                >
                                  {pathClicked[index] ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Box ref={pathClicked[index] ? comboTableRef : null}>
                            {pathClicked[index] && (
                              <TableCombo
                                tableList={menuList}
                                changeData={(value) =>
                                  changeData(value, "userDefaultPathSid", index)
                                }
                                type={"MENU"}
                              />
                            )}
                          </Box>
                        </TableCell>

                        <TableCell onClick={(event) => event.stopPropagation()}>
                          {row.userSid ? (
                            <IconButton
                              onClick={(event) => (
                                event.stopPropagation(), userDelete(row.userSid)
                              )}
                            >
                              <DeleteIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            timeout="auto"
                            unmountOnExit
                            in={open === row.userSid ? true : false}
                          >
                            <AdminUserModal props={open > 0 && open} />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
      {ynAlertShow && (
        <YNAlert
          contents="사용자를 로그아웃 시키시겠습니까?"
          handleClickYes={handleClickYes}
          handleClickNo={handleClickNo}
        />
      )}
    </div>
  );
}
