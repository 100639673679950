import {
  Box,
  Button,
  Collapse,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import AlertMain from "../Alert/AlertMain";
import {
  getCommonList,
  setCommonDelete,
  setCommonSave,
} from "../API/CommonAPI";
import { useEffect } from "react";
import AdminCommonModal from "./AdminCommonModal";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminCommon() {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    setData([]);
    setOriginData([]);
    getCommonList("", setErrFlag, setLoadFlag)
      .then((result) => result && (setData(result), setOriginData(result)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, [flag]);

  const addClick = () => {
    setData([
      ...data,
      {
        id: data.length,
        commonSid: null,
        commonSeq: 0,
        commonType: "",
        value1Char: "",
        value2Char: "",
        value3Char: "",
        value4Char: "",
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };

  const changeData = (value, col, colIndex) => {
    setData((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );
  };

  const commonSave = () => {
    const saveData = data.filter((item) => item.changeFlag === "Y");

    setCommonSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const commonCancel = () => {
    setData(originData);
  };

  const commonDelete = (commonSid) => {
    setCommonDelete(commonSid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const colOpen = (commonSid) => {
    if (open === commonSid) {
      setOpen("");
    } else {
      setOpen(commonSid);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
            ml: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              mb: 2,
              ml: 2,
            }}
          >
            <Typography className="menuTitle">공통 관리</Typography>
            <Box sx={{ mr: 1 }}>
              <SaveButton
                variant="contained"
                sx={{ mr: 1 }}
                onClick={commonSave}
              >
                저장
              </SaveButton>
              <CancelButton variant="outlined" onClick={commonCancel}>
                취소
              </CancelButton>
            </Box>
          </Box>
          <TableContainer
            sx={{
              borderRadius: 1,
              width: "90vw",
              maxHeight: "70vh",
              overflowX: "auto",
            }}
          >
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ width: "3%" }}></TableCell>
                  <TableCell>지정타입</TableCell>
                  <TableCell>순서</TableCell>
                  <TableCell>Value1</TableCell>
                  <TableCell>Value2</TableCell>
                  <TableCell>Value3</TableCell>
                  <TableCell>Value4</TableCell>
                  <TableCell sx={{ width: "10%" }}>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.map((row, index) => (
                    <React.Fragment>
                      <TableRow key={index}>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(event) => (
                              colOpen(row.commonSid), event.stopPropagation()
                            )}
                          >
                            {open === row.commonSid ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>{index + 1}</TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.commonType}
                            onChange={(e) => {
                              changeData(e.target.value, "commonType", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.commonSeq}
                            onChange={(e) => {
                              changeData(e.target.value, "commonSeq", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          {" "}
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.value1Char}
                            onChange={(e) => {
                              changeData(e.target.value, "value1Char", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          {" "}
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.value2Char}
                            onChange={(e) => {
                              changeData(e.target.value, "value2Char", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          {" "}
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.value3Char}
                            onChange={(e) => {
                              changeData(e.target.value, "value3Char", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          {" "}
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.value4Char}
                            onChange={(e) => {
                              changeData(e.target.value, "value4Char", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        {row.commonSid ? (
                          <TableCell sx={{ padding: 0 }}>
                            <IconButton
                              onClick={(event) => (
                                event.stopPropagation(),
                                commonDelete(row.commonSid)
                              )}
                            >
                              <DeleteIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={12}
                        >
                          <Collapse
                            timeout="auto"
                            unmountOnExit
                            in={open === row.commonSid ? true : false}
                          >
                            <AdminCommonModal props={open > 0 && open} />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: "right" }}>
            <IconButton onClick={addClick} sx={{ mt: 1 }}>
              <AddCircleIcon sx={{ fontSize: 48, color: "#729F76" }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
