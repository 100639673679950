import React from "react";

import { getUserMenuAll } from "../API/MenuAPI";
import { useEffect } from "react";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { useState, useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableContainer,
} from "@mui/material";
import {
  getMenuDropDown,
  getMenuListAll,
  setUserMenuSave,
  setUserMenuDelete,
} from "../API/MenuAPI";
import "../Main/Table.css";
import TableCombo from "../Util/TableCombo";

export default function AdminUserModal({ props }) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [dataModal, setDataModal] = useState([]);
  const [originDataModal, setOriginDataModal] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [flagModal, setFlagModal] = useState(false);
  const [menuClicked, setMenuClicked] = useState({});
  const comboTableRef = useRef();

  useEffect(() => {
    getMenuDropDown(setErrFlag, setLoadFlag)
      .then((result) => result && setMenuList(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);
  useEffect(() => {
    if (props) {
      userMenuCancel();
      getUserMenuAll(props, setErrFlag, setLoadFlag)
        .then(
          (result) =>
            result && (setDataModal(result), setOriginDataModal(result))
        )
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [props, flagModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setMenuClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addClickModal = () => {
    setDataModal([
      ...dataModal,
      {
        id: dataModal.length,
        userMenuSid: null,
        userSid: props,
        menuSid: null,
        sortNum: 0,
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };
  const changeDataModal = (value, col, colIndex) => {
    setDataModal((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );

    if (col === "menuGuid") {
      setMenuClicked({});
    }
  };

  const userMenuSave = () => {
    const saveData = dataModal.filter((item) => item.changeFlag === "Y");

    setUserMenuSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlagModal(!flagModal))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const userMenuCancel = () => {
    setDataModal(originDataModal);
  };

  const userMenuDelete = (userMenuGuid) => {
    setUserMenuDelete(userMenuGuid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlagModal(!flagModal))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const pathTableOpen = (index) => {
    setMenuClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Box sx={{ m: 2, display: "flex" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
              mb: 2,
              ml: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontFamily: "Nanum Gothic",
                fontSize: "1.2rem",
              }}
            >
              유저 권한 부여
            </Typography>
            <Box>
              <Button
                variant="text"
                sx={{ color: "#305633" }}
                onClick={userMenuSave}
              >
                저장
              </Button>
              <Button
                variant="text"
                sx={{ color: "#768977" }}
                onClick={userMenuCancel}
              >
                취소
              </Button>
            </Box>
          </Box>
          <TableContainer sx={{ borderRadius: 1, width: "40vw" }}>
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#F4F5EC" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>메뉴</TableCell>
                  <TableCell>순번</TableCell>
                  <TableCell>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataModal &&
                  dataModal.map((rowCol, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          value={
                            rowCol.menuSid &&
                            menuList.find(
                              (item) => item.guid === rowCol.menuSid
                            )?.value2
                          }
                          onClick={(event) => {
                            pathTableOpen(index);
                            event.stopPropagation();
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  pathTableOpen(index);
                                  event.stopPropagation();
                                }}
                              >
                                {menuClicked[index] ? (
                                  <ArrowDropUpIcon />
                                ) : (
                                  <ArrowDropDownIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <Box ref={menuClicked[index] ? comboTableRef : null}>
                          {menuClicked[index] && (
                            <TableCombo
                              tableList={menuList}
                              changeData={(value) =>
                                changeDataModal(value, "menuSid", index)
                              }
                              type={"MENU2"}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={rowCol.sortNum}
                          onChange={(e) => {
                            changeDataModal(e.target.value, "sortNum", index);
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell onClick={(event) => event.stopPropagation()}>
                        {rowCol.userMenuSid ? (
                          <IconButton
                            onClick={(event) => (
                              event.stopPropagation(),
                              userMenuDelete(rowCol.userMenuSid)
                            )}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: "right" }}>
            <IconButton onClick={addClickModal} sx={{ mt: 1 }}>
              <AddCircleIcon sx={{ fontSize: 45, color: "#729F76" }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
