import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [errFlag, setErrFlag] = useState({ code: "N", data: "" });
  const [loadFlag, setLoadFlag] = useState(false);
  const [mainFlag, setMainFlag] = useState(false);
  const [chartFlag, setChartFlag] = useState(false);
  const [monValue, setMonValue] = useState("");
  const [dgFlag, setDgFlag] = useState(false);
  const [userMenu, setUserMenu] = useState([]);
  const [alarmData, setAlarmData] = useState([]);
  const [socket, setSocket] = useState(null);

  return (
    <AppContext.Provider
      value={{
        errFlag,
        setErrFlag,
        loadFlag,
        setLoadFlag,
        mainFlag,
        setMainFlag,
        chartFlag,
        setChartFlag,
        monValue,
        setMonValue,
        dgFlag,
        setDgFlag,
        userMenu,
        setUserMenu,
        alarmData,
        setAlarmData,
        socket,
        setSocket,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
