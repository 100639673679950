import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { setAirSphDelete, setAirSphSave } from "../../API/AirAPI";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminAirlinesFile({
  fileData,
  clickAdd,
  changeData,
  airlineSpcFileCancel,
  airlineSpcSave,
  airlineSphDlete,
}) {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Paper
          sx={{
            p: 2,
            mb: 1,
            ml: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              mb: 2,
              ml: 2,
            }}
          >
            <Typography className="menuTitle">서류 관리</Typography>
            <Box sx={{ mr: 1 }}>
              <SaveButton
                variant="contained"
                sx={{ mr: 1 }}
                onClick={airlineSpcSave}
              >
                저장
              </SaveButton>
              <CancelButton variant="outlined" onClick={airlineSpcFileCancel}>
                취소
              </CancelButton>
            </Box>
          </Box>
          <TableContainer sx={{ borderRadius: 1, width: "30vw" }}>
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ width: "50%" }}>파일 종류</TableCell>
                  <TableCell sx={{ width: "20%" }}>순서</TableCell>
                  <TableCell>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileData &&
                  fileData.map((row, index) => (
                    <React.Fragment>
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.sphValue1}
                            onChange={(e) => {
                              changeData(e.target.value, "sphValue1", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        <TableCell>
                          <Input
                            className="admin-input"
                            sx={{
                              "&::before": {
                                borderBottom: "none",
                              },
                              "&::after": {
                                borderBottom: "none",
                              },
                            }}
                            value={row.sphSeq}
                            onChange={(e) => {
                              changeData(e.target.value, "sphSeq", index);
                              e.stopPropagation();
                            }}
                          ></Input>
                        </TableCell>
                        {row.airSphGuid ? (
                          <TableCell sx={{ padding: 0 }}>
                            <IconButton
                              onClick={(event) => (
                                event.stopPropagation(),
                                airlineSphDlete(row.airSphGuid)
                              )}
                            >
                              <DeleteIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: "right" }}>
            <IconButton onClick={clickAdd} sx={{ mt: 1 }}>
              <AddCircleIcon sx={{ fontSize: 48, color: "#729F76" }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </div>
  );
}
