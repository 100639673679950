import * as React from "react";
import { useState } from "react";
import { Box, Backdrop, Typography } from "@mui/material";
import SearchAlert from "./SearchAlert";
import ReceivedAlert from "./ReceivedAlert";
import Header from "../Main/Header";
import { useEffect } from "react";
import { AppContext } from "../AppContext";
import { useContext } from "react";

function AlertMain() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("R");
  const [filterData, setFilterData] = useState();

  const handleAlertOpen = () => {
    setOpen(true);
  };

  const handleAlertClose = () => {
    setOpen(false);
  };

  const handleBoxClick = (event) => {
    event.stopPropagation();
  };

  const filterList = (props) => {
    setFilterData(props);
  };

  return (
    <div>
      <div style={{ height: 120 }}>
        <Header handleOpen={handleAlertOpen} />
      </div>
      <Backdrop sx={{ zIndex: 2000 }} open={open} onClick={handleAlertClose}>
        <Box
          onClick={handleBoxClick}
          sx={{
            position: "fixed",
            right: 0,
            top: 0,
            width: "20%",
            height: "100%",
            backgroundColor: "white",
            transition: "transform 0.3s ease-out",
            transform: open ? "translateX(0)" : "translateX(100%)",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Noto Sans KR",
                  fontSize: 18,
                  fontWeight: "bold",
                  ml: 0.5,
                }}
              >
                받은 알림
              </Typography>
              <SearchAlert props={filterList} />
            </Box>
            <ReceivedAlert filterData={filterData} flag={handleAlertClose} />
          </Box>
        </Box>
      </Backdrop>
    </div>
  );
}

export default AlertMain;
