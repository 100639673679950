import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import "../Main/Table.css";
import { AppContext } from "../AppContext";
import { getSpcEmailList } from "../API/SpcAPI";
import SquareIcon from "@mui/icons-material/Square";

export default function DgModal({ props }) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [dataModal, setDataModal] = useState([]);
  useEffect(() => {
    if (props) {
      getSpcEmailList(props, setErrFlag, setLoadFlag)
        .then((result) => result && setDataModal(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [props]);
  return (
    <Box sx={{ m: 2, display: "flex" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
            pb: 2,
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 3,
              mb: 2,
              ml: 2,
            }}
          >
            <SquareIcon sx={{ fontSize: 10, mt: 0.4, mr: 1 }} />
            <Typography
              sx={{
                fontWeight: "bold",
                fontFamily: "Noto Sans KR",
                fontSize: 16,
              }}
            >
              REJECT 이력
            </Typography>
          </Box>
          <TableContainer sx={{ borderRadius: 1, width: "75vw" }}>
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#e9f3f4" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell sx={{ width: "20%" }}>MAWB</TableCell>
                  <TableCell sx={{ width: "30%" }}>REJECT 사유</TableCell>
                  <TableCell sx={{ width: "25%" }}>Email</TableCell>
                  <TableCell>날짜</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataModal &&
                  dataModal.map((row, index) => (
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.emailSubject &&
                          row.emailSubject.replace(
                            /^(\d{3})(\d{4})(\d+)$/,
                            `$1-$2-$3`
                          )}
                      </TableCell>
                      <TableCell>{row.emailBody && row.emailBody}</TableCell>
                      <TableCell>
                        {row.emailReceiveUser && row.emailReceiveUser}
                      </TableCell>
                      <TableCell>
                        {row.createdTime &&
                          new Intl.DateTimeFormat("ko-KR", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                            timeZone: "Asia/Seoul",
                          }).format(new Date(row.createdTime))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
}
