import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import { useState } from "react";

export default function ViewFile({
  url,
  len,
  openClick,
  setFileIdx,
  fileName,
}) {
  const [scale, setScale] = useState(1);

  const zoomInClick = () => {
    setScale((prevScale) => Math.min(prevScale + 0.3, 2.5));
  };
  const zoomOutClick = () => {
    setScale((prevScale) => Math.max(prevScale - 0.3, 1));
  };
  const upClick = () => {
    setScale(1);
    setFileIdx((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : len - 1));
  };
  const downClick = () => {
    setScale(1);
    setFileIdx((prevIndex) => (prevIndex < len - 1 ? prevIndex + 1 : 0));
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "60vw",
          height: "95vh",
          backgroundColor: "#323639",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "4vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              visibility:
                String(url.type).toUpperCase() !== "PDF" ? "visible" : "hidden",
            }}
          >
            <IconButton
              sx={{
                zIndex: 9999,
                ml: 1,
                mr: 1,
                mt: 0.5,
              }}
              onClick={zoomInClick}
            >
              <ZoomInIcon sx={{ color: "#FFF" }} />
            </IconButton>
            <IconButton
              sx={{
                zIndex: 9999,
                mt: 0.5,
              }}
              onClick={zoomOutClick}
            >
              <ZoomOutIcon sx={{ color: "#FFF" }} />
            </IconButton>
          </Box>
          <Typography sx={{ color: "#FFF", fontFamily: "Noto Sans KR" }}>
            {fileName}
          </Typography>
          <Box>
            <IconButton
              sx={{
                zIndex: 9999,
                mt: 0.5,
              }}
              onClick={upClick}
            >
              <KeyboardArrowUpIcon sx={{ color: "#FFF" }} />
            </IconButton>
            <IconButton
              sx={{
                zIndex: 9999,
                mr: 1,
                mt: 0.5,
              }}
              onClick={downClick}
            >
              <KeyboardArrowDownIcon sx={{ color: "#FFF" }} />
            </IconButton>
            <IconButton
              sx={{
                zIndex: 9999,
                mr: 1,
                mt: 0.5,
              }}
              onClick={openClick}
            >
              <CloseIcon sx={{ color: "#FFF" }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            height: "91vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {String(url.type).toUpperCase() === "PDF" ? (
            <iframe
              src={url && url.data}
              style={{
                width: "60vw",
                height: "91vh",
                border: "none",
              }}
              title="pdf"
              id="tempfrm"
            />
          ) : (
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <img
                src={url.data}
                style={{
                  display: "block",
                  maxWidth: "60vw",
                  maxHeight: "90vh",
                  objectFit: "contain",
                  transform: `scale(${scale})`,
                  transition: "transform 0.2s ease",
                }}
                alt="img"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
