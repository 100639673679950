import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const CssTextField = styled(TextField)(({ rootBorder }) => ({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },

  "& input": {
    fontFamily: "Noto Sans KR",
    textAlign: "right",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: rootBorder || "1px solid #E0E3E7", //"2px solid #6F7E8C",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
}));

export const EmtButton = styled(Button)(({ theme, fontWt }) => ({
  fontFamily: "Noto Sans KR",
  fontWeight: fontWt || 500,
  color: "#00114F",
  borderColor: "#00114F",
  "&:hover": {
    borderColor: "#00114F",
    backgroundColor: "#EFF3FA",
  },
}));

export const FilButton = styled(Button)(({ theme, fontWt }) => ({
  fontFamily: "Noto Sans KR",
  fontWeight: fontWt || 500,
  color: "#fff",
  backgroundColor: "#00114FDD",
  "&:hover": {
    backgroundColor: "#00114FCC",
  },
}));

export const LabeledTextField = ({
  startAdornmentText,
  endAdornmentProps,
  inBorder,
  ...props
}) => {
  return (
    <CssTextField
      rootBorder={inBorder}
      {...props}
      sx={{ backgroundColor: "#fff" }}
      fullWidth
      size="small"
      required
      autoComplete="off"
      InputProps={{
        startAdornment: startAdornmentText && (
          <InputAdornment position="start">
            <Typography
              sx={{
                color: "#000000DD",
                fontFamily: "Noto Sans KR",
                fontWeight: 500,
                fontSize: "1.05rem",
              }}
            >
              {startAdornmentText}
            </Typography>
          </InputAdornment>
        ),
        endAdornment: endAdornmentProps && (
          <InputAdornment position="end">
            <IconButton
              sx={endAdornmentProps.sx}
              onClick={endAdornmentProps.onClick}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
