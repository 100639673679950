export function register() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-message-sw.js") // 서비스 워커 파일 경로
        .then((registration) => {
          console.log("ServiceWorker 등록 성공:", registration);
        })
        .catch((error) => {
          console.log("ServiceWorker 등록 실패:", error);
        });
    });
  }
}

// 서비스 워커 해제
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
