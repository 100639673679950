import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Badge,
  List,
  ListItem,
  Divider,
  ListItemText,
  Typography,
  Modal,
  styled,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import moment from "moment";
import { setAlarmRead, getAlarmInfo } from "../API/AlarmAPI";
import { useNavigate } from "react-router-dom";

const HoverList = styled(List)({
  "& .MuiListItem-root:hover": {
    backgroundColor: "#f5f5f5",
  },
});

const RedBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: red[500],
    color: theme.palette.text.primary,
    top: 8,
    right: 4,
  },
}));

function ReceivedAlert({ filterData, flag }) {
  const navigator = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [unReadAlarm, setUnReadAlarm] = useState([]);
  const [recentAlarm, setRecentAlarm] = useState([]);
  const [prevAlarm, setPrevAlarm] = useState([]);
  const { setErrFlag, setLoadFlag, alarmData, userMenu } =
    useContext(AppContext);

  useEffect(() => {
    if (filterData) {
      const convData =
        alarmData &&
        alarmData.filter(
          (item) =>
            String(item.alarmEntity.title).includes(filterData) ||
            String(item.alarmEntity.body).includes(filterData) ||
            String(item.sendUsername).includes(filterData)
        );
      setFilteredData(convData);
    } else {
      setFilteredData(alarmData);
    }
  }, [alarmData, filterData]);

  useEffect(() => {
    const now = Date.now();

    const oneDay = 24 * 60 * 60 * 1000;
    const oneWeekAgo = now - 7 * oneDay;
    const oneMonthAgo = now - 31 * oneDay;

    const unReadAlarm = filteredData.filter(
      (item) =>
        item.alarmEntity.readFlag === "N" &&
        item.alarmEntity.createTime >= oneMonthAgo
    );
    const recentAlarm = filteredData.filter(
      (item) =>
        item.alarmEntity.readFlag !== "N" &&
        item.alarmEntity.createTime >= oneWeekAgo
    );
    const prevAlarm = filteredData.filter(
      (item) =>
        item.alarmEntity.readFlag !== "N" &&
        item.alarmEntity.createTime >= oneMonthAgo &&
        item.alarmEntity.createTime < oneWeekAgo
    );

    setUnReadAlarm(unReadAlarm);
    setRecentAlarm(recentAlarm);
    setPrevAlarm(prevAlarm);
  }, [filteredData]);

  // 모두 읽음 처리
  const readAllClick = () => {
    const unReadGuid = unReadAlarm.map((item) => item.alarmEntity.alarmSid);

    setAlarmRead(unReadGuid, setErrFlag, setLoadFlag).catch((error) =>
      setErrFlag({ code: "Y", data: error.message })
    );
  };

  const readClick = async (alarmGuid) => {
    const alarmList = alarmData.filter(
      (item) => item.alarmEntity.alarmSid === alarmGuid
    );
    const path = userMenu
      .filter((item) => item.menuGuid === alarmList[0]?.alarmEntity?.menuSid)
      .map((item) => item.menuPath);
    var pathStr = "";
    var valueArray = null;
    if (path[0]) {
      pathStr = path[0];
    } else {
      flag();
      setErrFlag({ code: "Y", data: "권한없음" });
      return;
    }

    await getAlarmInfo(alarmGuid, setErrFlag, setLoadFlag)
      .then((result) => {
        if (result) {
          valueArray = { ...result };
        }
      })
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    if (valueArray) {
      navigator(pathStr);
      await onlyReadClick(alarmGuid);
      navigator(pathStr + `/${valueArray.value1}` + `/${valueArray.value2}`);
      flag();
    }
  };

  const onlyReadClick = async (alarmGuid) => {
    const unReadGuid = [alarmGuid];
    await setAlarmRead(unReadGuid, setErrFlag, setLoadFlag).catch((error) =>
      setErrFlag({ code: "Y", data: error.message })
    );
  };

  return (
    <Box>
      <Box sx={{ maxHeight: "85vh", overflowY: "auto" }}>
        <HoverList>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              pt: 0,
              pb: 0,
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Jeju Gothic",
                backgroundColor: "#EFF2F5",
                fontSize: 15,

                pl: 1,
                pt: 0.3,
                pb: 0.3,
                mb: 0.3,
              }}
            >
              읽지 않음
            </Typography>
            {unReadAlarm &&
              unReadAlarm.map((item) => (
                <>
                  <ListItem
                    alignItems="flex-start"
                    onClick={(e) => (
                      e.stopPropagation(), readClick(item.alarmEntity.alarmSid)
                    )}
                  >
                    <ListItemText
                      primary={item.alarmEntity.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", fontSize: 14 }}
                            component="span"
                            color="text.primary"
                          >
                            {item.alarmEntity.body !== null
                              ? item.alarmEntity.body
                              : "\u00A0"}
                          </Typography>
                          <Box>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                            >
                              {moment(item.alarmEntity.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                ml: 1,
                                fontWeight: "bold",
                              }}
                              component="span"
                              variant="body2"
                            >
                              {item.sendUsername}
                            </Typography>
                          </Box>
                        </React.Fragment>
                      }
                    />
                    {item.alarmEntity.readFlag === "N" && (
                      <RedBadge badgeContent=" " variant="dot" />
                    )}
                    <Button
                      size="small"
                      sx={{ position: "absolute", right: 5, bottom: 5 }}
                      onClick={(e) => (
                        e.stopPropagation(),
                        onlyReadClick(item.alarmEntity.alarmSid)
                      )}
                    >
                      읽음
                    </Button>
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Jeju Gothic",
                backgroundColor: "#EFF2F5",
                fontSize: 15,
                pl: 1,
                pt: 0.3,
                pb: 0.3,
                mt: 0.5,
                mb: 0.3,
              }}
            >
              최근 7일
            </Typography>
            {recentAlarm &&
              recentAlarm.map((item) => (
                <>
                  <ListItem
                    alignItems="flex-start"
                    onClick={(e) => (
                      e.stopPropagation(), readClick(item.alarmEntity.alarmSid)
                    )}
                  >
                    <ListItemText
                      primary={item.alarmEntity.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", fontSize: 14 }}
                            component="span"
                            color="text.primary"
                          >
                            {item.alarmEntity.body !== null
                              ? item.alarmEntity.body
                              : ""}
                          </Typography>
                          <Box>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                            >
                              {moment(item.alarmEntity.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                ml: 1,
                                fontWeight: "bold",
                              }}
                              component="span"
                              variant="body2"
                            >
                              {item.sendUsername}
                            </Typography>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "Jeju Gothic",
                backgroundColor: "#EFF2F5",
                fontSize: 15,

                pl: 1,
                pt: 0.3,
                pb: 0.3,
                mt: 0.5,
                mb: 0.3,
              }}
            >
              이전 알림
            </Typography>
            {prevAlarm &&
              prevAlarm.map((item) => (
                <>
                  <ListItem
                    alignItems="flex-start"
                    onClick={(e) => (
                      e.stopPropagation(), readClick(item.alarmEntity.alarmSid)
                    )}
                  >
                    <ListItemText
                      primary={item.alarmEntity.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: "inline", fontSize: 14 }}
                            component="span"
                            color="text.primary"
                          >
                            {item.alarmEntity.body !== null
                              ? item.alarmEntity.body
                              : ""}
                          </Typography>
                          <Box>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                            >
                              {moment(item.alarmEntity.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                display: "inline",
                                ml: 1,
                                fontWeight: "bold",
                              }}
                              component="span"
                              variant="body2"
                            >
                              {item.sendUsername}
                            </Typography>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
          </List>
        </HoverList>
      </Box>
      <Box sx={{ position: "fixed", bottom: 1, right: 1 }}>
        <Button onClick={readAllClick}>모두 읽음</Button>
      </Box>
    </Box>
  );
}

export default ReceivedAlert;
