function getApiUrl() {
  if (!window._SCHEDULE_APP_ || !window._SCHEDULE_APP_.REACT_APP_API_URL) {
    throw new Error("API URL is not defined. Make sure config.json is loaded.");
  }
  return window._SCHEDULE_APP_.REACT_APP_API_URL;
}
export const getMenuListAll = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/menu/menuList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          menuSid: row.menuSid,
          sortNum: row.sortNum,
          menuPath: row.menuPath ? row.menuPath : "",
          menuName: row.menuName,
          fileName: row.fileName && row.fileName,
          serverInfo: row.serverInfo && row.serverInfo,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getMenuDropDown = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/menu/menuList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row.menuSid,
          value2: row.menuName,
          value3: row.menuPath ? row.menuPath : "",
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setMenuSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/menu/menuSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setMenuDelete = async (menuGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/menu/menuDelete?menuGuid=${menuGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getUserMenuAll = async (userGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/menu/userMenu?userGuid=${userGuid}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          userMenuSid: row.userMenuSid,
          sortNum: row.sortNum,
          userSid: row.userSid,
          menuSid: row.menuSid,
          changeFlag: "N",
          usableFlag: row.usableFlag,
        }));

        setLoadFlag(false);

        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setUserMenuDelete = async (
  userMenuGuid,
  setErrFlag,
  setLoadFlag
) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/menu/userMenuDelete?userMenuGuid=${userMenuGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setUserMenuSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/menu/userMenuSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getMenuList = async (userGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/menu/menuLoad?userGuid=${userGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          menuGuid: row.menuSid,
          sortNum: row.sortNum,
          menuPath: row.menuPath,
          menuName: row.menuName,
          fileName: row.fileName,
          serverInfo: row.serverInfo,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
