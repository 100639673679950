import React from "react";
import { useEffect } from "react";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { useState, useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
} from "@mui/material";
import "../Main/Table.css";
import {
  getCommonSubList,
  setCommonSubDelete,
  setCommonSubSave,
} from "../API/CommonAPI";

export default function AdminCommonModal({ props }) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [dataModal, setDataModal] = useState([]);
  const [originDataModal, setOriginDataModal] = useState([]);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (props) {
      setDataModal([]);
      setOriginDataModal([]);
      getCommonSubList(props, setErrFlag, setLoadFlag)
        .then(
          (result) =>
            result && (setDataModal(result), setOriginDataModal(result))
        )
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [props, flag]);
  const addClickModal = () => {
    setDataModal([
      ...dataModal,
      {
        id: dataModal.length,
        commonSid: props,
        commonSubSid: null,
        commonSeq: 0,
        commonSubType: "",
        value1Char: "",
        value2Char: "",
        value3Char: "",
        value4Char: "",
        value5Char: "",
        value6Char: "",
        value7Char: "",
        value8Char: "",
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };
  const changeDataModal = (value, col, colIndex) => {
    setDataModal((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );
  };

  const subCommonSave = () => {
    const saveData = dataModal.filter((item) => item.changeFlag === "Y");

    setCommonSubSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };
  const subCommonCancel = () => {
    setDataModal(originDataModal);
  };

  const subCommonDelete = (subCommonGuid) => {
    setCommonSubDelete(subCommonGuid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  return (
    <Box sx={{ m: 2, display: "flex" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              mt: 3,
              mb: 2,
              ml: 2,
            }}
          >
            <Box>
              <Button
                variant="text"
                sx={{ color: "#305633" }}
                onClick={subCommonSave}
              >
                저장
              </Button>
              <Button
                variant="text"
                sx={{ color: "#768977" }}
                onClick={subCommonCancel}
              >
                취소
              </Button>
            </Box>
          </Box>
          <TableContainer sx={{ borderRadius: 1, width: "75vw" }}>
            <Table className="admin-table">
              <TableHead sx={{ backgroundColor: "#F4F5EC" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>지정타입</TableCell>
                  <TableCell sx={{ width: "5%" }}>순서</TableCell>
                  <TableCell>Value1</TableCell>
                  <TableCell>Value2</TableCell>
                  <TableCell>Value3</TableCell>
                  <TableCell>Value4</TableCell>
                  <TableCell>Value5</TableCell>
                  <TableCell>Value6</TableCell>
                  <TableCell>Value7</TableCell>
                  <TableCell>Value8</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>삭제</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataModal &&
                  dataModal.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.commonSubType}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "commonSubType",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.commonSeq}
                          onChange={(e) => {
                            changeDataModal(e.target.value, "commonSeq", index);
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value1Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value1Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value2Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value2Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value3Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value3Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value4Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value4Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value5Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value5Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value6Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value6Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value7Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value7Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell>
                        <Input
                          className="admin-input"
                          sx={{
                            "&::before": {
                              borderBottom: "none",
                            },
                            "&::after": {
                              borderBottom: "none",
                            },
                          }}
                          value={row.value8Char}
                          onChange={(e) => {
                            changeDataModal(
                              e.target.value,
                              "value8Char",
                              index
                            );
                            e.stopPropagation();
                          }}
                        ></Input>
                      </TableCell>
                      <TableCell onClick={(event) => event.stopPropagation()}>
                        {row.commonSubSid ? (
                          <IconButton
                            onClick={(event) => (
                              event.stopPropagation(),
                              subCommonDelete(row.commonSubSid)
                            )}
                          >
                            <DeleteIcon sx={{ fontSize: 18 }} />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: "right" }}>
            <IconButton onClick={addClickModal} sx={{ mt: 1 }}>
              <AddCircleIcon sx={{ fontSize: 45, color: "#729F76" }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
