import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled,
  Collapse,
} from "@mui/material";
import React from "react";
import AlertMain from "../Alert/AlertMain";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import { getDeptAllList, setDeptDelete, setDeptSave } from "../API/DeptAPI";
import "../Main/Table.css";
import AdminDeptEdit from "./AdminDeptEdit";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const processDataToTree = (data) => {
  const tree = {};
  const lookup = {};

  data.forEach((item) => {
    lookup[item.deptSid] = { ...item, children: [] };
    if (item.parentDeptSid === null) {
      tree[item.deptSid] = lookup[item.deptSid];
    } else {
      if (!lookup[item.parentDeptSid]) {
        lookup[item.parentDeptSid] = { children: [] };
      }
      lookup[item.parentDeptSid].children.push(lookup[item.deptSid]);
    }
  });

  return Object.values(tree);
};
export default function AdminDept() {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [convertData, setConvertData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [flag, setFlag] = useState({ flag: false, deptSid: 0 });
  const [guid, setGuid] = useState("");
  const [data, setData] = useState([]);
  const [openState, setOpenState] = useState({});

  useEffect(() => {
    getDeptAllList(setErrFlag, setLoadFlag)
      .then((result) => result && (setData(result), setOriginData(result)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    setConvertData(processDataToTree(data));
  }, [data]);

  const editClick = (guid) => {
    if (flag.flag && flag.deptSid === guid) {
      setFlag({ flag: false, deptSid: guid });
    } else {
      setFlag({ flag: false, deptSid: flag.deptSid });
      setTimeout(() => {
        setFlag({ flag: true, deptSid: guid });
      }, 200);
    }
  };

  const addClick = (seq, deptGuid) => {
    if (guid.length > 21) {
      setErrFlag({ code: "Y", data: "부서 임시추가는 20개까지" });
      return;
    }
    setData([
      ...data,
      {
        deptSid: guid,
        deptName: "부서명",
        deptSeq: seq,
        parentDeptSid: deptGuid,
        userSid: "대표유저명",
        changeFlag: "Y",
        terminal: null,
      },
    ]);
    setGuid(guid + "a");
  };

  const mainAddClick = () => {
    if (guid.length > 21) {
      setErrFlag({ code: "Y", data: "부서 임시추가는 20개까지" });
      return;
    }
    setData([
      ...data,
      {
        deptSid: guid,
        deptName: "부서명",
        deptSeq: 0,
        parentDeptSid: null,
        userSid: "대표유저명",
        changeFlag: "Y",
        terminal: null,
      },
    ]);
    setGuid(guid + "a");
  };

  const cancelData = () => {
    setFlag({ flag: false, deptGuid: "" });
    setData(originData);
  };

  const deleteData = (deptGuid) => {
    setDeptDelete(deptGuid, setErrFlag, setLoadFlag)
      .then(
        (result) =>
          result &&
          getDeptAllList(setErrFlag, setLoadFlag)
            .then(
              (result) => result && (setData(result), setOriginData(result))
            )
            .catch((error) => setErrFlag({ code: "Y", data: error.message }))
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const saveData = () => {
    const saveData = data.filter((item) => item.changeFlag === "Y");

    setDeptSave(saveData, setErrFlag, setLoadFlag)
      .then(
        (result) =>
          result &&
          (getDeptAllList(setErrFlag, setLoadFlag)
            .then(
              (result) => result && (setData(result), setOriginData(result))
            )
            .catch((error) => setErrFlag({ code: "Y", data: error.message })),
          setFlag({ flag: false, deptGuid: "" }))
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const EntryLoop = ({ entry, depth }) => {
    const handleOpen = () => {
      setOpenState((prev) => ({
        ...prev,
        [entry.deptSid]: !prev[entry.deptSid],
      }));
    };

    return (
      <Box sx={{ textAlign: "left", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={(e) => {
            handleOpen();
          }}
        >
          <Box sx={{ display: "flex" }}>
            {!!entry.children && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen();
                }}
                sx={{ ml: depth * 1 }}
              >
                {openState[entry.deptSid] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                fontFamily: "Nanum Gothic",
                marginLeft: 1,
              }}
            >
              {entry.deptName}
            </Typography>
          </Box>
          <Box sx={{ mr: 2 }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpenState((prev) => ({ ...prev, [entry.deptSid]: true }));
                addClick(Number(entry.deptSeq) + 1, entry.deptSid);
                editClick(guid);
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                editClick(entry.deptSid);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteData(entry.deptSid);
                setFlag({ flag: false, deptSid: flag.deptSid });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>

        <Collapse in={openState[entry.deptSid]} unmountOnExit>
          {!!entry.children &&
            entry.children.map((children, idx) => (
              <EntryLoop
                entry={children}
                depth={depth + 1}
                key={`${entry.id} + ${depth} + ${idx}`}
              />
            ))}
        </Collapse>
      </Box>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Paper
            sx={{
              pr: 2,
              pl: 2,
              pb: 2,
              ml: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 3,
                mb: 3,
                ml: 2,
              }}
            >
              <Typography className="menuTitle">부서 관리</Typography>
              <SaveButton
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => {
                  mainAddClick();
                  editClick(guid);
                }}
              >
                추가
              </SaveButton>
            </Box>
            <Paper sx={{ width: 800 }}>
              {convertData &&
                convertData.map((entry) => (
                  <EntryLoop entry={entry} depth={1} key={entry.id} />
                ))}
            </Paper>
          </Paper>
        </Box>
        <Collapse orientation="horizontal" in={flag.flag}>
          <AdminDeptEdit
            flag={flag}
            data={data}
            setData={setData}
            saveData={saveData}
            cancelData={cancelData}
          />
        </Collapse>
      </Box>
    </div>
  );
}
