import {
  Box,
  Button,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import TableCombo from "../../Util/TableCombo";
import AdminAirlinesFile from "./AdminAirlinesFile";
import { getCommonTypeSubType } from "../../API/CommonAPI";
import {
  getAirSphList,
  setAirSphAllDelete,
  setAirSphDelete,
  setAirSphSave,
} from "../../API/AirAPI";

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminAirlinesSpc({ handleToAirlines, airSid }) {
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [flag, setFlag] = useState(false);
  const [airSphList, setAirSphList] = useState([]);
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [originFileData, setOriginFileData] = useState([]);
  const [open, setOpen] = useState(false);
  const [sphType, setSphType] = useState();
  const [spc1Data, setSpc1Data] = useState([]);
  const [spc2Data, setSpc2Data] = useState([]);
  const [spcData, setSpcData] = useState([]);
  const [spcDataClicked, setSpcDataClicked] = useState({});
  const comboTableRef = useRef();

  useEffect(() => {
    if (airSid>0) {
      setOpen(false);
      getAirSphList(airSid, setErrFlag, setLoadFlag)
        .then((result) => result && setAirSphList(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [airSid, flag]);

  useEffect(() => {
    getCommonTypeSubType("SPCCD", "SPC1", setErrFlag, setLoadFlag)
      .then((result) => result && setSpc1Data(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));

    getCommonTypeSubType("SPCCD", "SPC2", setErrFlag, setLoadFlag)
      .then((result) => result && setSpc2Data(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    const combinedData = [...spc1Data, ...spc2Data];
    const transformed = [];
    if (spc1Data && spc2Data && spc1Data.length > 0 && spc2Data.length > 0) {
      combinedData.forEach((item, index) => {
        for (let i = 1; i <= 8; i++) {
          const valueCharKey = `value${i}Char`;
          if (item[valueCharKey]) {
            transformed.push({
              guid: item[valueCharKey],
              value1: transformed.length,
              value2: item[valueCharKey],
            });
          }
        }
      });
      setSpcData(transformed);
    }
  }, [spc1Data, spc2Data]);

  useEffect(() => {
    if (airSphList) {
      const groupedData = airSphList.reduce((acc, item) => {
        const key = `${item.airSid}-${item.sphType}`;
        if (!acc[key]) {
          acc[key] = { ...item };
        }
        return acc;
      }, {});
      setData(Object.values(groupedData));
      setOriginData(Object.values(groupedData));
    }
  }, [airSphList]);

  useEffect(() => {
    const filteredData = airSphList.filter((item) => item.sphType === sphType);
    setFileData(filteredData);
    setOriginFileData(filteredData);
  }, [airSphList, sphType, flag]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setSpcDataClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addClick = () => {
    setOpen(false);
    setData([
      ...data,
      {
        id: data.length,
        airSphGuid: null,
        airSid: airSid,
        sphType: "",
        sphSeq: null,
        sphValue1: "",
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };

  const addClick2 = () => {
    setFileData([
      ...fileData,
      {
        id: fileData.length,
        airSphGuid: null,
        airSid: airSid,
        sphType: sphType,
        sphSeq: 0,
        sphValue1: "",
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };

  const changeData = (value, col, colIndex) => {
    setData((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );
    if (col === "sphType") {
      setSpcDataClicked({});
      setSphType(value);
      setOpen(true);
      if (data.find((item) => item.sphType === value)) {
        setData(originData);
      }
    }
  };

  const changeData2 = (value, col, colIndex) => {
    setFileData((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );
  };

  const spcDataTableOpen = (index) => {
    setSpcDataClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const airlineSpcSave = () => {
    const saveData = fileData.filter((item) => item.changeFlag === "Y");

    setAirSphSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const airlineSpcCancel = () => {
    setOpen(false);
    setData(originData);
  };

  const airlineSpcFileCancel = () => {
    setOpen(false);
    setFileData(originFileData);
  };

  const airlineSphAllDlete = (airSid, type) => {
    setAirSphAllDelete(airSid, type, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const airlineSphDlete = (airSphGuid) => {
    setAirSphDelete(airSphGuid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const handleRowClick = (newSphType) => {
    if (newSphType) {
      if (newSphType === sphType) {
        setOpen((prev) => !prev);
      } else {
        setOpen(false);
        setTimeout(() => {
          setSphType(newSphType);
          setOpen(true);
        }, 200);
      }
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Paper
          sx={{
            pr: 2,
            pl: 2,
            pb: 2,
            mb: 1,
            ml: 10,
          }}
        >
          <Typography
            sx={{
              color: "#103977",
              fontSize: 15,
              fontWeight: "bold",
              fontFamily: "Noto Sans KR",
              textAlign: "left",
              pt: 2,
              pl: 1,
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={handleToAirlines}
          >
            ← 항공사 관리
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              mb: 2,
              ml: 2,
            }}
          >
            <Typography className="menuTitle">스페셜 코드 관리</Typography>
            <Box sx={{ mr: 1 }}>
              <CancelButton variant="outlined" onClick={airlineSpcCancel}>
                취소
              </CancelButton>
            </Box>
          </Box>
          <Box>
            <TableContainer sx={{ borderRadius: 1, width: "30vw" }}>
              <Table className="admin-table">
                <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>스페셜 코드</TableCell>
                    <TableCell sx={{ width: "25%" }}>삭제</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((row, index) => (
                      <React.Fragment>
                        <TableRow
                          hover
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(row.sphType);
                          }}
                          sx={{
                            backgroundColor:
                              row.sphType === sphType && open && "#F5F5F5",
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                justifyContent: "center",
                              }}
                            >
                              <Input
                                className="admin-input"
                                sx={{
                                  "&::before": {
                                    borderBottom: "none",
                                  },
                                  "&::after": {
                                    borderBottom: "none",
                                  },
                                }}
                                value={
                                  row.sphType && spcData
                                    ? spcData.find(
                                        (item) => item.value2 === row.sphType
                                      )?.value2 || ""
                                    : ""
                                }
                                onClick={(event) => {
                                  spcDataTableOpen(index);
                                  event.stopPropagation();
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={(event) => {
                                        spcDataTableOpen(index);
                                        event.stopPropagation();
                                      }}
                                    >
                                      {spcDataClicked[index] ? (
                                        <ArrowDropUpIcon />
                                      ) : (
                                        <ArrowDropDownIcon />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <Box
                                ref={
                                  spcDataClicked[index] ? comboTableRef : null
                                }
                              >
                                {spcDataClicked[index] && (
                                  <TableCombo
                                    tableList={spcData && spcData}
                                    changeData={(value) =>
                                      changeData(value, "sphType", index)
                                    }
                                    width="15%"
                                    type={"SPCCD"}
                                  />
                                )}
                              </Box>
                            </Box>
                          </TableCell>

                          {row.airSid ? (
                            <TableCell sx={{ padding: 0 }}>
                              <IconButton
                                onClick={(event) => (
                                  event.stopPropagation(),
                                  airlineSphAllDlete(row.airSid, row.sphType)
                                )}
                              >
                                <DeleteIcon sx={{ fontSize: 18 }} />
                              </IconButton>
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <IconButton onClick={addClick} sx={{ mt: 1 }}>
              <AddCircleIcon sx={{ fontSize: 48, color: "#729F76" }} />
            </IconButton>
          </Box>
        </Paper>
      </Box>
      <Collapse orientation="horizontal" in={open}>
        <AdminAirlinesFile
          fileData={fileData}
          clickAdd={addClick2}
          changeData={changeData2}
          airlineSpcFileCancel={airlineSpcFileCancel}
          airlineSpcSave={airlineSpcSave}
          airlineSphDlete={airlineSphDlete}
        />
      </Collapse>
    </Box>
  );
}
