import { Box, CssBaseline, IconButton } from "@mui/joy";
import { AppBar, Badge, Typography, Link } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { useContext } from "react";
import logo from "../image/AACTIcon.png";
import { getMenuList } from "../API/MenuAPI";

function Header({ handleOpen }) {
  const [isFixed, setIsFixed] = useState(true);
  const [alarmCount, setAlarmCount] = useState(0);
  const { setErrFlag, setLoadFlag, userMenu, setUserMenu, alarmData, socket } =
    useContext(AppContext);
  const navigator = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset <= 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (alarmData) {
      const tempArray = alarmData.filter(
        (item) => item.alarmEntity?.readFlag === "N"
      );

      setAlarmCount(tempArray.length);
    }
  }, [alarmData]);

  useEffect(() => {
    const userGuid = sessionStorage.getItem("userSid");

    getMenuList(userGuid, setErrFlag, setLoadFlag)
      .then((result) => result && setUserMenu(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, [sessionStorage.getItem("userSid")]);

  const logoutClick = async () => {
    if (socket) {
      sessionStorage.clear();
      socket.close();
      navigator("/");
    }
  };

  const naviClick = (path) => {
    navigator(path);
  };

  const logoClick = async (event) => {
    event.preventDefault();

    navigator(sessionStorage.getItem("path"));
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position={isFixed ? "fixed" : "static"}
        sx={{
          top: 0,
          bottom: "auto",
          display: isFixed ? "block" : "none",
          backgroundColor: "#002626",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "white",
            mt: 3,
            mb: 3,
            mr: 7,
            ml: 10,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <img
              src={logo}
              alt="logo"
              width="110px"
              onClick={logoClick}
              style={{ width: "110px", cursor: "pointer" }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {userMenu &&
              userMenu.map((row) => (
                <Typography
                  onClick={(event) => (
                    naviClick(row.menuPath), event.stopPropagation()
                  )}
                  variant="h5"
                  sx={{
                    ml: 3,
                    cursor: "pointer",
                    fontSize: 19,
                    fontFamily: "Noto Sans KR",
                  }}
                >
                  {row.menuName}
                </Typography>
              ))}
            <Link
              href=""
              variant="body2"
              sx={{
                textDecoration: "none",
                color: "white",
                mr: 3,
                ml: 6,
                fontFamily: "Nanum Gothic",
              }}
              onClick={(event) => (logoutClick(), event.stopPropagation())}
            >
              로그아웃
            </Link>
            <IconButton
              onClick={handleOpen}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(65, 183, 183, 0.2)",
                },
              }}
            >
              <Badge badgeContent={alarmCount && alarmCount} color="info">
                <NotificationsNoneIcon
                  sx={{ cursor: "pointer", color: "white" }}
                />
              </Badge>
            </IconButton>
          </Box>
        </Box>
      </AppBar>
    </Box>
  );
}

export default Header;
