import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReplyIcon from "@mui/icons-material/Reply";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useEffect, useRef, useState } from "react";
import AlertMain from "../../Alert/AlertMain";
import { getCode } from "../../API/MainAPI";
import { AppContext } from "../../AppContext";
import TableCombo from "../../Util/TableCombo";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "./Airlines.css";
import AdminAirlinesSpc from "./AdminAirlinesSpc";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAirList, setAirDelete, setAirSave } from "../../API/AirAPI";

const SaveButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#729F76",
  "&:hover": {
    backgroundColor: "#567359",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: "#729F76",
  borderColor: "#729F76",
  "&:hover": {
    borderColor: "#729F76",
    backgroundColor: "#E5F4E6",
  },
}));

export default function AdminAirlines() {
  const { value1 } = useParams();
  const [flag, setFlag] = useState(false);
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [guid, setGuid] = useState(0);
  const [trmDropDown, setTrmDropDown] = useState([]);
  const [trmClicked, setTrmClicked] = useState({});
  const comboTableRef = useRef();
  const navigator = useNavigate();

  useEffect(() => {
    if (swiperInstance && value1) {
      swiperInstance.slideTo(value1);
    } else {
      if (swiperInstance) {
        swiperInstance.slideTo(0);
      }
    }
  }, [value1]);

  useEffect(() => {
    setData([]);
    setOriginData([]);
    getAirList(setErrFlag, setLoadFlag)
      .then((result) => result && (setData(result), setOriginData(result)))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, [flag]);

  useEffect(() => {
    getCode("TRMCD")
      .then((result) => result && setTrmDropDown(result))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setTrmClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addClick = () => {
    setData([
      ...data,
      {
        id: data.length,
        airSid: null,
        airSeq: 0,
        airName: "",
        carrier2Code: "",
        carrier3Code: "",
        prefix: "",
        nationCode: "",
        terminalCode: "",
        airPhone: "",
        usableFlag: "Y",
        changeFlag: "Y",
      },
    ]);
  };

  const changeData = (value, col, colIndex) => {
    setData((prevData) =>
      prevData.map((item, index) =>
        index === colIndex ? { ...item, [col]: value, changeFlag: "Y" } : item
      )
    );
    if (col === "terminalCode") {
      setTrmClicked({});
    }
  };

  const trmTableOpen = (index, event) => {
    setTrmClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleToAirlines = () => {
    navigator("/AdminAirlines");
  };

  const airlineSave = () => {
    const saveData = data.filter((item) => item.changeFlag === "Y");

    setAirSave(saveData, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const airlineCancel = () => {
    setData(originData);
  };

  const airlineDlete = (airSid) => {
    setAirDelete(airSid, setErrFlag, setLoadFlag)
      .then((result) => result && setFlag(!flag))
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const colOpen = (airSid) => {
    setGuid(airSid);

    //   if (swiperInstance) {
    //     swiperInstance.slideTo(1);
    //   }
    navigator("/AdminAirlines/1");
  };
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        overflowY: "auto",
      }}
    >
      <div style={{ height: 110 }}>
        <AlertMain />
      </div>
      <Swiper
        slidesPerView={1}
        modules={[Pagination]}
        onSwiper={setSwiperInstance}
        touchRatio={0}
        className="mySwiper"
      >
        <SwiperSlide>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                pr: 2,
                pl: 2,
                pb: 2,
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 3,
                  mb: 2,
                  ml: 2,
                }}
              >
                <Typography className="menuTitle">항공사 관리</Typography>
                <Box sx={{ mr: 1 }}>
                  <SaveButton
                    variant="contained"
                    sx={{ mr: 1 }}
                    onClick={airlineSave}
                  >
                    저장
                  </SaveButton>
                  <CancelButton variant="outlined" onClick={airlineCancel}>
                    취소
                  </CancelButton>
                </Box>
              </Box>
              <TableContainer
                sx={{ borderRadius: 1, width: "80vw", minHeight: "67.4vh" }}
              >
                <Table className="admin-table">
                  <TableHead sx={{ backgroundColor: "#d9e5d6" }}>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>항공사</TableCell>
                      <TableCell sx={{ width: "8%" }}>순서</TableCell>
                      <TableCell sx={{ width: "8%" }}>IATA</TableCell>
                      <TableCell sx={{ width: "8%" }}>ICAO</TableCell>
                      <TableCell sx={{ width: "8%" }}>Prefix</TableCell>
                      <TableCell sx={{ width: "8%" }}>국가 코드</TableCell>
                      <TableCell sx={{ width: "8%" }}>터미널</TableCell>
                      <TableCell>연락처</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: "8%" }}>
                        스페셜 코드 관리
                      </TableCell>

                      <TableCell sx={{ width: "10%" }}>삭제</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <React.Fragment>
                            <TableRow key={row.airSid}>
                              <TableCell>
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.airName}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "airName",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.airSeq}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "airSeq",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.carrier2Code}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "carrier2Code",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.carrier3Code}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "carrier3Code",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.prefix}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "prefix",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.nationCode}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "nationCode",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <Box
                                  sx={{
                                    justifyContent: "center",
                                    paddingLeft: 1,
                                  }}
                                >
                                  <Input
                                    className="admin-input"
                                    sx={{
                                      "&::before": {
                                        borderBottom: "none",
                                      },
                                      "&::after": {
                                        borderBottom: "none",
                                      },
                                    }}
                                    value={
                                      row.terminalCode && trmDropDown
                                        ? trmDropDown.find(
                                            (item) =>
                                              item.value2 === row.terminalCode
                                          )?.value2 || ""
                                        : ""
                                    }
                                    ref={
                                      trmClicked[page * rowsPerPage + index]
                                        ? comboTableRef
                                        : null
                                    }
                                    onClick={(event) => {
                                      trmTableOpen(page * rowsPerPage + index);
                                      event.stopPropagation();
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={(event) => {
                                            trmTableOpen(
                                              page * rowsPerPage + index
                                            );
                                            event.stopPropagation();
                                          }}
                                        >
                                          {trmClicked[
                                            page * rowsPerPage + index
                                          ] ? (
                                            <ArrowDropUpIcon />
                                          ) : (
                                            <ArrowDropDownIcon />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />

                                  {trmClicked[page * rowsPerPage + index] && (
                                    <Box>
                                      <TableCombo
                                        tableList={trmDropDown && trmDropDown}
                                        changeData={(value) =>
                                          changeData(
                                            value,
                                            "terminalCode",
                                            page * rowsPerPage + index
                                          )
                                        }
                                        type={"TRMCD"}
                                        width="12%"
                                        top={
                                          comboTableRef && (
                                            <TableCell>
                                              <Box
                                                sx={{
                                                  justifyContent: "center",
                                                  paddingLeft: 1,
                                                }}
                                              >
                                                <Input
                                                  className="admin-input"
                                                  sx={{
                                                    "&::before": {
                                                      borderBottom: "none",
                                                    },
                                                    "&::after": {
                                                      borderBottom: "none",
                                                    },
                                                  }}
                                                  value={
                                                    row.terminalCode &&
                                                    trmDropDown
                                                      ? trmDropDown.find(
                                                          (item) =>
                                                            item.value2 ===
                                                            row.terminalCode
                                                        )?.value2 || ""
                                                      : ""
                                                  }
                                                  ref={
                                                    trmClicked[
                                                      page * rowsPerPage + index
                                                    ]
                                                      ? comboTableRef
                                                      : null
                                                  }
                                                  onClick={(event) => {
                                                    trmTableOpen(
                                                      page * rowsPerPage + index
                                                    );
                                                    event.stopPropagation();
                                                  }}
                                                  endAdornment={
                                                    <InputAdornment position="end">
                                                      <IconButton
                                                        onClick={(event) => {
                                                          trmTableOpen(index);
                                                          event.stopPropagation();
                                                        }}
                                                      >
                                                        {trmClicked[
                                                          page * rowsPerPage +
                                                            index
                                                        ] ? (
                                                          <ArrowDropUpIcon />
                                                        ) : (
                                                          <ArrowDropDownIcon />
                                                        )}
                                                      </IconButton>
                                                    </InputAdornment>
                                                  }
                                                />

                                                {trmClicked[
                                                  page * rowsPerPage + index
                                                ] && (
                                                  <Box>
                                                    <TableCombo
                                                      tableList={
                                                        trmDropDown &&
                                                        trmDropDown
                                                      }
                                                      changeData={(value) =>
                                                        changeData(
                                                          value,
                                                          "terminalCode",
                                                          page * rowsPerPage +
                                                            index
                                                        )
                                                      }
                                                      type={"TRMCD"}
                                                      width="12%"
                                                    />
                                                  </Box>
                                                )}
                                              </Box>
                                            </TableCell>
                                          )
                                        }
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Input
                                  className="admin-input"
                                  sx={{
                                    "&::before": {
                                      borderBottom: "none",
                                    },
                                    "&::after": {
                                      borderBottom: "none",
                                    },
                                  }}
                                  value={row.airPhone}
                                  onChange={(e) => {
                                    changeData(
                                      e.target.value,
                                      "airPhone",
                                      page * rowsPerPage + index
                                    );
                                    e.stopPropagation();
                                  }}
                                ></Input>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(event) => (
                                    colOpen(row.airSid), event.stopPropagation()
                                  )}
                                >
                                  <ReplyIcon sx={{ transform: "scaleX(-1)" }} />
                                </IconButton>
                              </TableCell>
                              {row.airSid ? (
                                <TableCell sx={{ padding: 0 }}>
                                  <IconButton
                                    onClick={(event) => (
                                      event.stopPropagation(),
                                      airlineDlete(row.airSid)
                                    )}
                                  >
                                    <DeleteIcon sx={{ fontSize: 18 }} />
                                  </IconButton>
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                            </TableRow>
                          </React.Fragment>
                        ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={data && data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ mt: -1 }}
                />
              </TableContainer>
              <Box sx={{ textAlign: "right" }}>
                <IconButton onClick={addClick} sx={{ mt: 1 }}>
                  <AddCircleIcon sx={{ fontSize: 48, color: "#729F76" }} />
                </IconButton>
              </Box>
            </Paper>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <AdminAirlinesSpc handleToAirlines={handleToAirlines} airSid={guid} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
