function getApiUrl() {
  if (!window._SCHEDULE_APP_ || !window._SCHEDULE_APP_.REACT_APP_API_URL) {
    throw new Error("API URL is not defined. Make sure config.json is loaded.");
  }
  return window._SCHEDULE_APP_.REACT_APP_API_URL;
}
export const getUserListAll = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/user/userList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          userSid: row.userEntity?.userSid,
          userId: row.userEntity?.userId,
          userName: row.userEntity?.userName,
          userPassword: row.userEntity?.userPassword,
          userSubDeptSid: row.userEntity?.userSubDeptSid,
          usableFlag: row.userEntity?.usableFlag,
          userApprove: row.userEntity?.userApprove,
          userMainDeptSid: row.userEntity?.userMainDeptSid,
          userDefaultPathSid: row.userEntity?.userDefaultPathSid,
          onlineFlag: row.onlineFlag,
          changeFlag: "N",
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getUserListAllDropDown = async (setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/user/userList`);

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          userSid: row.userSid,
          userId: row.userId,
          userName: row.userName,
          userPassword: row.userPassword,
          userPriority: row.userPriority,
          usableFlag: row.usableFlag,
          userApprove: row.userApprove,
          userDepart: row.userDepart,
          userDefaultPath: row.userDefaultPath,
          changeFlag: "N",
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setUserSave = async (data, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/user/userSave`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const setUserDelete = async (userGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/user/userDelete?userGuid=${userGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const adminLogout = async (userGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/user/adminLogout?userGuid=${userGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
export const getUserEntities = async (userGuid, setErrFlag, setLoadFlag) => {
  setErrFlag({ code: "N", data: "" });
  setLoadFlag(true);
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/user/userInfo?userGuid=${userGuid}`
    );

    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      setLoadFlag(false);
      return res;
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
