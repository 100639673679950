import React, { useState, useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Logo from "../image/AACT_logo.png";
import { assignSelect } from "../API/LoginAPI";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { getDeptMostList, getDeptLowUserListExcept } from "../API/DeptAPI";
import TableCombo from "../Util/TableCombo";
import { useEffect } from "react";
import { Label } from "@mui/icons-material";
import { useRef } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "../Main/Table.css";
import { getAirCombo } from "../API/AirAPI";

const defaultTheme = createTheme();

function Signup() {
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    password: "",
    chkPassword: "",
    mainDept: "",
    subDept: "",
  });
  const { setErrFlag, setLoadFlag } = useContext(AppContext);
  const [selectMainDept, setSelectMainDept] = useState("");
  const [deptCombo, setDeptCombo] = useState([]);
  const [mainDept, setMainDept] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showChkPassword, setShowChkPassword] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [memberType, setMemberType] = useState("");
  const navigator = useNavigate();
  const [deptClicked, setDeptClicked] = useState({});

  const tableRef = useRef(null);
  const comboTableRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        comboTableRef.current &&
        !comboTableRef.current.contains(event.target)
      ) {
        setDeptClicked({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // 항공사 리스트, 부서 리스트 불러오기
  useEffect(() => {
    getDeptMostList()
      .then(
        (result) =>
          result && (setMainDept(result), setSelectMainDept(result[0].deptSid))
      )
      .catch((error) => setErrFlag({ code: "Y", data: error.message }));
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "chkPassword") {
      setPasswordMismatch(value !== formData.password);
    }
  };

  const deptChange = (value) => {
    setFormData({
      ...formData,
      ["mainDept"]: selectMainDept,
      ["subDept"]: value,
    });

    setDeptClicked({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      userId: formData.id,
      userPassword: formData.password,
      userName: formData.name,
      usableFlag: "Y",
      userMainDeptSid: formData.mainDept,
      userSubDeptSid: formData.subDept,
    };
    if (await assignSelect(data, setErrFlag, setLoadFlag)) {
      navigator("/");
    }
  };

  useEffect(() => {
    setDeptCombo([]);

    if (selectMainDept === "AIR") {
      getAirCombo()
        .then((result) => result && setDeptCombo(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    } else {
      getDeptLowUserListExcept(selectMainDept)
        .then((result) => result && setDeptCombo(result))
        .catch((error) => setErrFlag({ code: "Y", data: error.message }));
    }
  }, [selectMainDept]);

  const handleTypeChange = (event) => {
    setFormData({
      ...formData,
      ["mainDept"]: "",
      ["subDept"]: "",
    });
    setSelectMainDept(event.target.value);
    setDeptClicked({});
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleChkPasswordVisibility = () => {
    setShowChkPassword(!showChkPassword);
  };

  const isFormIncomplete = (
    memberType === "aact"
      ? ["name", "id", "password", "chkPassword", "dept"]
      : ["name", "id", "password", "chkPassword", "crrcd"]
  ).some((field) => formData[field] === "");
  const isInvalidId = formData.id && !/^[a-zA-Z0-9]+$/.test(formData.id);

  const deptTableOpen = (index) => {
    setDeptClicked((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
        <Container component="main">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Paper sx={{ pt: 5, pb: 3, pr: 10, pl: 10 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    회원가입
                  </Typography>
                </Box>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 3, width: "25vw" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="department"
                          name="department"
                          value={selectMainDept}
                          onChange={handleTypeChange}
                          row
                        >
                          {mainDept &&
                            mainDept.map((item) => (
                              <FormControlLabel
                                key={item.deptSid}
                                value={item.deptSid}
                                control={<Radio />}
                                label={item.deptName}
                              />
                            ))}
                          <FormControlLabel
                            value="AIR"
                            control={<Radio />}
                            label="항공사"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="given-name"
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="이름"
                        autoFocus
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          justifyContent: "center",
                        }}
                      >
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>
                            {selectMainDept && selectMainDept === "AIR" ? (
                              "항공사 *"
                            ) : (
                              <>
                                {mainDept &&
                                  selectMainDept &&
                                  mainDept.find(
                                    (item) =>
                                      item.deptSid === Number(selectMainDept)
                                  )?.deptName}
                                &nbsp; *
                              </>
                            )}
                          </InputLabel>
                          <OutlinedInput
                            label="1111111"
                            value={
                              formData &&
                              formData.subDept &&
                              deptCombo.find(
                                (item) => item.guid === formData.subDept
                              )?.value2
                            }
                            onClick={(event) => {
                              deptTableOpen(0);
                              event.stopPropagation();
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(event) => {
                                    deptTableOpen(0);
                                    event.stopPropagation();
                                  }}
                                >
                                  {deptClicked[0] ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <Box ref={deptClicked[0] ? comboTableRef : null}>
                          {deptClicked[0] && (
                            <TableCombo
                              tableList={deptCombo && deptCombo}
                              changeData={(value) => deptChange(value)}
                              type={
                                selectMainDept === "AIR" ? "CRRCD" : "DEPT2"
                              }
                              width={"24.5%"}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="id"
                        label="아이디"
                        name="id"
                        autoComplete="id"
                        onChange={handleChange}
                        error={isInvalidId}
                        helperText={
                          isInvalidId ? "숫자와 영문만 사용해주세요." : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="비밀번호"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="chkPassword"
                        label="비밀번호 확인"
                        type={showChkPassword ? "text" : "password"}
                        id="chk-password"
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={toggleChkPasswordVisibility}
                              edge="end"
                            >
                              {showChkPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          ),
                        }}
                        error={passwordMismatch}
                        helperText={
                          passwordMismatch ? "비밀번호가 다릅니다." : ""
                        }
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isFormIncomplete}
                  >
                    회원가입
                  </Button>
                  {isFormIncomplete && (
                    <Typography
                      color="error"
                      variant="body2"
                      align="center"
                      sx={{ mb: 1 }}
                    >
                      * 모든 정보를 입력해주세요.
                    </Typography>
                  )}
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link href="/" variant="body2">
                        로그인
                      </Link>
                    </Grid>
                  </Grid>
                  <img
                    src={Logo}
                    alt="logo"
                    style={{
                      width: "80px",
                      marginTop: 4,
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Signup;
