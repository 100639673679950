function getApiUrl() {
  if (!window._SCHEDULE_APP_ || !window._SCHEDULE_APP_.REACT_APP_API_URL) {
    throw new Error("API URL is not defined. Make sure config.json is loaded.");
  }
  return window._SCHEDULE_APP_.REACT_APP_API_URL;
}
export const getMasterAllList = async (
  userGuid,
  date,
  setErrFlag,
  setLoadFlag
) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/intra/getMasterAllList?userGuid=${userGuid}&date=${date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "데이터없음" });
    } else {
      if (res.errCode === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          intraGuid: row.masterEntities.intraSid,
          intraType: row.masterEntities.intraType,
          intraStartTime: row.masterEntities.intraStartTime,
          intraEndTime: row.masterEntities.intraEndTime,
          intraImportantFlag: row.masterEntities.intraImportantFlag,
          intraCompleteFlag: row.masterEntities.intraCompleteFlag,
          intraSubject: row.masterEntities.intraSubject,
          intraDept: row.masterEntities.intraDept,
          intraDeptStr: row.intraDeptStr,
          userName: row.userName,
          dateData: row.dateData.map((rows) => ({
            detailGuid: rows.detailSid,
            contents: rows.contents,
            dateValue: rows.dateValue,
          })),
          dataStatus: row.dataStatus,
        }));
        setLoadFlag(false);
        return data;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }

  setLoadFlag(false);
  return false;
};

export const rowDel = async (masterGuid, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/intra/setMasterRowDel?masterGuid=${masterGuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    console.log(error);
    setErrFlag({ code: "Y", data: error.message });
  }

  setLoadFlag(false);
  return false;
};
export const personalRowAddClick = async (
  userGuid,
  setErrFlag,
  setLoadFlag
) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/intra/setMasterRowAdd?userGuid=${userGuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
      setErrFlag({ code: "Y", data: "데이터없음" });
    } else {
      if (res.errCode === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};
export const getUserList = async () => {
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/user/userList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          id: index,
          userSid: row.userSid,
          userId: row.userId,
          userName: row.userName,
        }));
        return data;
      }
    }
  } catch (error) {
    console.log(error.data);
  }
  return false;
};

export const getUserListDropDown = async () => {
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/user/userList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data
          .filter((row) => row.userEntity?.userMainDeptSid > 0)
          .map((row, index) => ({
            value1: index,
            guid: row.userEntity?.userSid,
            value2: row.userEntity?.userId,
            value3: row.userEntity?.userName,
          }));
        return data;
      }
    }
  } catch (error) {
    console.log(error.data);
  }
  return false;
};

export const masterSave = async (dto, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/intra/setMasterSubject`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dto),
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getAlarmCount = async (userGuid) => {
  if (!userGuid) return;
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/alarm/alarmCount?userGuid=${userGuid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        return Number(res.data);
      }
    }
  } catch (error) {}
};

export const setMasterTime = async (data, setErrFlag, setLoadFlag) => {
  setLoadFlag(true);
  setErrFlag({ code: "N", data: "" });
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/intra/setMasterTime`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
        setErrFlag({ code: "Y", data: res.data });
      } else {
        setLoadFlag(false);
        return true;
      }
    }
  } catch (error) {
    setErrFlag({ code: "Y", data: error.message });
  }
  setLoadFlag(false);
  return false;
};

export const getDPTCDDropDown = async () => {
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(`${apiUrl}/intra/deptCombo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }

    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errFlag === "Y") {
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row.deptSid,
          value2: row.deptName,
          value3: row.userGuid,
        }));
        return data;
      }
    }
  } catch (error) {
    console.log(error.data);
  }
  return false;
};

export const getCode = async (classCode) => {
  try {
    const apiUrl = getApiUrl();
    const response = await fetch(
      `${apiUrl}/util/getCode?classCode=${classCode}`
    );
    if (!response.ok) {
      const res = await response.json();
      throw new Error(Array(res)[0].data);
    }
    const res = await response.json();
    if (res.length === 0) {
    } else {
      if (res.errorFlag === "Y") {
        throw new Error(res.data);
      } else {
        const data = res.data.map((row, index) => ({
          value1: index,
          guid: row[0],
          value2: row[0],
          value3: row[1],
        }));

        return data;
      }
    }
  } catch (error) {
    console.log(error.message);
  }
  return false;
};
